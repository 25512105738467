"use client";

import DownloadEventsIcon from "@heroicons/react/24/solid/ArrowDownTrayIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { useAtomValue } from "jotai";
import { useRouter } from "next/navigation";
import React, { useEffect } from "react";

import { css, cx } from "~/panda/css";
import { whitespace } from "~/react/components/whitespace";
import { chatAtomFor, currentChatIdAtom, streamAtomLastActiveFor, useSupabaseAtom } from "../atoms";
import { usePatchAtomFamily } from "../atoms/use-patch-atom-family";
import { useAnchorScroll } from "../components/anchor";
import { DownloadLink } from "../components/download-link";
import { Message } from "../components/message";
import { deleteMessages } from "../db";
import { TInputEvent } from "../event-types";
import { useActivityStream } from "../hooks/use-activity-stream";
import { useChatEventStream } from "../hooks/use-event-stream";
import { shortIdFor } from "../ids";

type CurrentMessagesClientProps = {
  children?: React.ReactNode;
  aggregate_id: string;
  events: readonly TInputEvent[];
};
export const CurrentMessagesClient: React.FC<CurrentMessagesClientProps> = ({
  aggregate_id,
  children,
}) => {
  const router = useRouter();
  const supabase = useSupabaseAtom("authenticated");
  const stream = useAtomValue(usePatchAtomFamily(streamAtomLastActiveFor, aggregate_id));
  // const chat = useAtomValue(chatStateAtomFamily(aggregate_id));
  const chat = useAtomValue(usePatchAtomFamily(chatAtomFor, aggregate_id));
  // we do not observe the current chat id, but use it as a cue
  // we wait for the settled aggregate_id to be passed in to complete the transistion
  const currentChatId = useAtomValue(currentChatIdAtom);
  useEffect(() => {
    if (aggregate_id !== currentChatId)
      console.log("input:transition", shortIdFor(aggregate_id), shortIdFor(currentChatId ?? ""));
  }, [aggregate_id, currentChatId]);

  const anchor = useAnchorScroll([chat.log, stream]);
  return (
    <div
      className={cx("w-full min-h-full flex flex-col items-start justify-end p-1 px-2 relative")}
      style={{ flexGrow: 1 }}
    >
      <OpenActivityStream chatId={aggregate_id} />
      <div className={cx("absolute left-0 top-0 right-0 flex items-center justify-end")}>
        <DownloadLink
          className={cx(
            chat.log.length < 1 && "hidden",
            "p-3 flex gap-2 justify-center text-slate-300 hover:text-rose-400 active:text-rose-500",
          )}
          data={chat.log}
          filename={`honest-input-${aggregate_id}.json`}
        >
          <DownloadEventsIcon className="w-6 h-6" />
        </DownloadLink>
        <button
          onClick={async () => {
            router.prefetch("/honest/input");
            await deleteMessages(supabase!, aggregate_id);
            router.replace("/honest/input", { scroll: false });
            router.refresh();
          }}
          className={cx(
            chat.log.length < 1 && "hidden",
            "p-3 flex gap-2 justify-center text-slate-300 hover:text-rose-400 active:text-rose-500",
          )}
        >
          <TrashIcon className="w-6 h-6" />
        </button>
      </div>
      <div className="flex-grow h-full">{whitespace.em}</div>
      <span
        className={cx(
          // TODO: we really need to give draft mode the royal treatment …
          currentChatId !== "/" &&
            currentChatId !== aggregate_id &&
            "transition-all duration-150 opacity-0",
          css({ animation: "appear-up" }),
        )}
      >
        {chat.history.map((envelope) => (
          <Message key={envelope.id} {...envelope} />
        ))}
      </span>
      <div className="relative">
        <div ref={anchor.ref} className="absolute -top-64" />
      </div>

      <div
        className={cx(
          "transition-all",
          "duration-400",
          chat.log.length === 0 && "h-0",
          chat.log.length > 0 && "h-2",
          (chat.log.length > 1 || stream) && "h-5",
        )}
      />

      {children}

      <OpenEventStream key={aggregate_id} chatId={aggregate_id} />
    </div>
  );
};

function OpenActivityStream({ chatId }: { chatId: string }) {
  useActivityStream(chatId);
  return null;
}

function OpenEventStream({ chatId }: { chatId: string }) {
  useChatEventStream(chatId);
  return null;
}
