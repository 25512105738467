import { NextFont, NextFontWithVariable } from "next/dist/compiled/@next/font";
import {
  DM_Sans,
  Lexend,
  Nunito_Sans,
  Sono,
  Geologica,
  Wix_Madefor_Display,
  Poppins,
  /**
   * Display font winners
   * Poppins
   * Poppins is just perfect
   *
   * Geologica,
   * Geologica with sharpness, it's great for test, but too much personality for ui
   *
   * Wix_Madefor_Display
   * Wix_Madefor_Display is limited, but good at large sizes with minimal personality
   *   it's actually more event at the top of letters than poppins
   */

  // Nanum Pen Script for catchy handwriting

  /**
   * honorable mentions for display font
   * Golos_Text
   * Instrument_Sans
   */
} from "next/font/google";
import { Argument, css, cx } from "~/panda/css";

// export const poppins = Poppins({
//   subsets: ["latin"],
//   preload: true,
//   weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
// });

// export const geologica = Geologica({
//   subsets: ["latin"],
//   preload: true,
//   weight: "variable",
//   axes: ["CRSV", "SHRP", "slnt"],
//   display: "swap",
//   adjustFontFallback: false,
// });
// this is a good all purpose font, but not for messages
// export const lexend = Lexend({
//   subsets: ["latin"],
//   preload: false,
// });
// export const someFont = {
//   ...lexend,
//   className: cx(
//     lexend.className,
//     css({
//       // fontKerning: "auto",
//       fontWeight: "365",
//       // fontWeight: "350",
//       fontSize: ".95rem",
//       letterSpacing: "-0.025em",
//       wordSpacing: "0.025em",
//     }),
//   ),
// };

// // works well for long explanatory text
// export const nunito = Nunito_Sans({
//   subsets: ["latin"],
//   preload: false,
//   axes: ["YTLC", "opsz", "wdth"],
// });
// export const titleFont = {
//   ...nunito,
//   className: cx(
//     nunito.className,
//     css({
//       fontVariationSettings: "'YTLC' 480, 'wdth' 93, 'opsz' 11.5",
//       fontWeight: "440",
//       fontSize: "1.05rem",
//       // letterSpacing: "-0.01em",
//       letterSpacing: "-0.02em",
//     }),
//   ),
// };


export const dmsans = DM_Sans({
  subsets: ["latin"],
  preload: true,
  axes: ["opsz"],
  fallback: ["Poppins", "system-ui", "sans-serif"],
  // display: "block",
});
export const messageFont = {
  ...dmsans,
  className: cx(
    dmsans.className,
    css({
      fontSize: ".95rem",
      // fontWeight: "380",
      fontWeight: "425",
      // fontWeight: "450",
      // fontWeight: "500",
      letterSpacing: "-0.01em",
      // their color, needs dark mode
      // color: "rgb(55, 65, 81)",
    }),
  ),
};

const wixMadeforDisplay = Wix_Madefor_Display({
  subsets: ["latin"],
  weight: "variable",
  preload: true,
  display: "swap",
  adjustFontFallback: false,
});
export const navigationFont = {
  ...wixMadeforDisplay,
  className: cx(
    wixMadeforDisplay.className,
    css({
      fontWeight: "520",
      fontStretch: "80%",
    }),
  ),
};


// export const sono = Sono({
//   subsets: ["latin"],
//   preload: false,
//   axes: ["MONO"],
//   display: "swap",
//   adjustFontFallback: false,
// });
// export const monoFont = fontify(sono, css({ letterSpacing: "-0.1em" }));

function fontify(font: NextFont | NextFontWithVariable, ...classNames: Argument[]) {
  return {
    ...font,
    className: cx(font.className, ...classNames),
  };
}
