import { SupabaseAdminClient } from "~/lib/supabase/admin";
import { DraftAdminEvent, TActivityEndedEvent, TActivityStartedEvent } from "../event-types";
import { createStream } from "./create-stream";
import { TActivityStreamEvent, TActivityStreamMeta } from "./active-event-types";

export function createBroadcast<
  Topic extends string,
  Event extends TActivityStreamEvent,
  Meta extends TActivityStreamMeta = TActivityStreamMeta,
>(supabase: SupabaseAdminClient, topic: Topic, meta: Meta) {
  const stream = createStream<Topic, Event, Meta>(supabase, topic, meta);

  return {
    ...stream,

    get status() {
      return stream.status;
    },

    get aborted() {
      return stream.aborted;
    },

    async start() {
      await Promise.all([
        stream.start(),
        supabase.from("honest_input__events").insert(
          DraftAdminEvent<TActivityStartedEvent>({
            type: "activity",
            name: "activity:started",
            aggregate_id: meta.aggregateId,
            user_id: meta.userId,
            ref_id: meta.replyId,
            // TODO: include topic — is that the ref_id?
            data: {
              messageId: meta.replyId,
              // TODO: call streamId — this is not a dedicate resource? …
              //       no, leave as id, this is the id of the activity
              //       ok, so the channel shouldn't create the id then
              id: stream.id,
              // ugh, would much prefer these as ulid strings … but not for now
              eventId: meta.eventId,
            },
            source: "system",
          }),
        ),
      ]);
    },

    async end() {
      await supabase.from("honest_input__events").insert(
        DraftAdminEvent<TActivityEndedEvent>({
          type: "activity",
          name: "activity:ended",
          aggregate_id: meta.aggregateId,
          user_id: meta.userId,
          ref_id: meta.replyId,
          // TODO: add reason why it ended? Like aborted?
          data: {
            messageId: meta.replyId,
            id: stream.id,
            eventId: meta.eventId,
          },
          source: "system",
        }),
      );
      await stream.end();
    },
  };
}
