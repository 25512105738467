import type { SupabaseClient } from "@supabase/supabase-js";
import { Update } from "ts-toolbelt/out/Object/P/Update";
import { A } from "@mobily/ts-belt";
import { Database, TablesInsert } from "~/supabase/types";
import * as T from "./event-types";

export type HonestInputDatabase = Update<
  Database,
  ["public", "Tables", "honest_input__events", "Row"],
  HonestInputEvent
>;
// TODO:  reference new type directly
export type HonestInputEvent = T.TInputEvent;

type Client = SupabaseClient<HonestInputDatabase>;

export async function selectChatHistoryById<T extends SupabaseClient = Client>(
  client: T,
  aggregate_id: string,
) {
  const query = await client
    .from("honest_input__events")
    .select("*")
    .eq("aggregate_id", aggregate_id)
    .order("created_at", { ascending: true });

  return query.data == null
    ? query
    : {
        ...query,
        data: T.DecodeInputEventList(query.data),
      };
}

export async function selectAllChatHistory<T extends SupabaseClient = Client>(client: T) {
  const query = await client
    .from("honest_input__events")
    .select("*")
    .order("created_at", { ascending: true });

  if (query.data == null) {
    return query;
  } else {
    // group and sort events by aggregate_id
    const data = Object.fromEntries(
      Object.entries(
        <Record<PropertyKey, readonly T.TInputEvent[]>>(
          A.groupBy(T.DecodeInputEventList(query.data), (event) => event.aggregate_id)
        ),
      ).sort(([a], [b]) => (a > b ? 1 : -1)),
    );

    return { ...query, data };
  }
}

export async function selectMessages<T extends SupabaseClient = Client>(client: T) {
  const query = await client
    .from("honest_input__events")
    .select("*")
    .order("created_at", { ascending: true });

  return query.data == null
    ? query
    : {
        ...query,
        data: T.DecodeInputEventList(query.data),
      };
}

export async function selectMessage<T extends SupabaseClient = Client>(
  client: T,
  id: string | number,
) {
  return client
    .from("honest_input__events")
    .select("*")
    .eq("id", id)
    .order("created_at", { ascending: true });
}

export function insertMessage<T extends SupabaseClient = Client>(
  client: T,
  input: Pick<T.TMessageAddedEvent, "aggregate_id" | "data"> &
    (Pick<T.TMessageAddedEvent, "source" | "user_id"> | never),
) {
  const event: TablesInsert<"honest_input__events"> = (
    input.source || input.user_id ? T.DraftAdminEvent : T.DecodeDraftEvent
  )({
    type: "message",
    name: "message:added",
    ...input,
    source: input.source ?? "user",
  });

  return client.from("honest_input__events").insert(event).select("*").single();
}

export function deleteMessages(client: Client, aggregate_id: string) {
  return client.from("honest_input__events").delete().eq("aggregate_id", aggregate_id);
}

export function insertUserMessage(
  client: Client,
  aggregate_id: string,
  ref_id: string,
  text: T.TMessageAddedEvent["data"]["text"],
  source?: T.TMessageAddedEvent["source"],
) {
  const event: TablesInsert<"honest_input__events"> = T.DecodeDraftEvent({
    source: source ?? "user",
    type: "message",
    name: "message:added",
    aggregate_id,
    ref_id,
    data: {
      messageId: ref_id,
      text,

      kind: "message",
      role: "requestor",
      // documentId: aggregate_id,
      eventId: undefined,
      id: ref_id,
      instance: {
        type: "request",
        id: ref_id,
      },
    },
  });

  return client.from("honest_input__events").insert(event).select("*").single();
}
