import { useAtom } from "jotai";
import { useEffect, useRef } from "react";

import { HonestInputEvent } from "../db";
import { chatAtomFor, useSupabaseAtom } from "../atoms";
import { ChatStateDefault, ResetAction } from "../chat-reducer";
import { shortIdFor } from "../ids";

export type UseChatEventStream = {
  aggregate_id: string;
};

export function useChatEventStream(aggregate_id: string) {
  const supabase = useSupabaseAtom("authenticated");
  const [{ log }, dispatch] = useAtom(chatAtomFor(aggregate_id));

  const agid = useRef(aggregate_id);
  // reset on aggregate_id change
  useEffect(() => {
    if (agid.current === aggregate_id) {
      // console.log("aggregate id is unchanged", shortIdFor(aggregate_id));
      return;
    }

    console.log("aggregate_id changed", aggregate_id, agid.current, history.length);
    console.log("checking history");
    if (aggregate_id && history.length > 0 && log[0].aggregate_id === aggregate_id) {
      console.log("resetting events");
      dispatch(ResetAction(ChatStateDefault({ aggregate_id }), log));
    }
    console.log("update current aggregate_id");
    agid.current = aggregate_id;
  }, [aggregate_id]);

  // subscribe to realtime events
  useEffect(() => {
    if (!supabase) return;

    // FIXME: we have to establish the auth'd listener second otherwise it's lost
    const $aggregate = supabase
      .channel(`honest_input__events/${aggregate_id}`)
      .on<HonestInputEvent>(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "honest_input__events",
          filter: `aggregate_id=eq.${aggregate_id}`,
        },
        (payload) => {
          console.log(`Change to current aggregate: ${aggregate_id} !`, payload);
          if (payload.eventType === "INSERT") {
            dispatch(payload.new);
          }
          // delete
          else if (payload.eventType === "DELETE") {
            console.log("Filtering out deleted event", payload);
            dispatch(
              ResetAction(
                ChatStateDefault({ aggregate_id }),
                log.filter((event) => event.id !== payload.old.id),
              ),
            );
          }
          // update probably
          else {
            console.log("something else happened", payload);
          }
        },
      )
      .subscribe();

    return () => {
      $aggregate.unsubscribe();
      supabase.removeChannel($aggregate);
    };
  }, [supabase, aggregate_id]);
}
