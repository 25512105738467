export type DownloadLinkProps<T> = {
  children?: React.ReactNode;
  className?: string;
  data: T;
  filename: string;
};

export function DownloadLink<T>({ children, className, data, filename }: DownloadLinkProps<T>) {
  return (
    <a
      className={className}
      href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(data, null, 2))}`}
      download={filename}
    >
      {children}
    </a>
  );
}
