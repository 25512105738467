import { Static, TSchema, Type } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";
import { ISO_8601 } from "./datetime";

export const DateTime = Type.String({ pattern: ISO_8601.source });

////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////// unused, probably! ///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

export function Check<T extends TSchema>(schema: T, value: unknown): value is Static<T> {
  // a pass through of the typebox Value.Check method maintained to
  // optionally disable checks in production, or to only do so via sampling
  return Value.Check(schema, value);
}

export function ListCheck<T extends TSchema>(schema: T, values: unknown[]): values is Static<T>[] {
  return values.every((value) => Check(schema, value));
}

export function Assert<T extends TSchema>(schema: T, value: unknown): asserts value is Static<T> {
  if (!Check(schema, value)) {
    throw new Error(`Value does not match schema: ${schema}`);
  }
}

export function ListAssert<T extends TSchema>(
  schema: T,
  values?: null | unknown[],
): asserts values is Static<T>[] {
  if (!Array.isArray(values)) {
    throw new Error(`Expects ${schema.title}[], but received ${values}`);
  }
  if (!ListCheck(schema, values)) {
    throw new Error(`Values do not match schema: ${schema.title}`);
  }
}

export const Decode = Value.Decode;
export function DecodeList<T extends TSchema>(schema: T, values?: null | unknown[]) {
  if (!Array.isArray(values)) {
    throw new Error(`Expects ${schema.title}[], but received ${values}`);
  }
  return values.map((value) => Decode(schema, value));
}
