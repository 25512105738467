import { ObjectOptions, Static, Type as T, TLiteral, TObject, TSchema } from "@sinclair/typebox";
import { TypeCompiler } from "@sinclair/typebox/compiler";
import { pascalcase } from "~/tools/pascalcase";
import { DateTime, Decode } from "~/tools/typebox";
import { RefId } from "./ids";

export type TEvent<
  Type extends TLiteral<string> = TLiteral<string>,
  Name extends TLiteral<string> = TLiteral<string>,
  Data extends TObject = TObject,
> = Static<ReturnType<typeof InputEventType<Type, Name, Data>>>;
export type TInputEvent = Static<typeof InputEventUnion>;
export type TMessageAddedEvent = Static<typeof MessageAddedEvent>;
export type TMessageEditedEvent = Static<typeof MessageEditedEvent>;
export type TMessageDeletedEvent = Static<typeof MessageDeletedEvent>;
export type TPromptAddedEvent = Static<typeof PromptAddedEvent>;
export type TErrorAddedEvent = Static<typeof ErrorAddedEvent>;
export type TActivityStartedEvent = Static<typeof ActivityStartedEvent>;
export type TActivityEndedEvent = Static<typeof ActivityEndedEvent>;
export type TResponseAddedEvent = Static<typeof ResponseAddedEvent>;

type UserOmittedFields = "id" | "created_at" | "user_id";
type AdminOmittedFields = "id" | "created_at";
const draftOmittedFields = {
  user: ["id", "created_at", "user_id"],
  admin: ["id", "created_at"],
} as const;

export const InputEventType = <
  Type extends TLiteral<string>,
  Name extends TLiteral<string>,
  Data extends TObject,
>({
  type,
  name,
  data,
  ...options
}: { type: Type; name: Name; data: Data } & ObjectOptions) =>
  T.Object(
    {
      user_id: T.String({
        title: "UserId",
        description: "The user who created the event",
      }),
      aggregate_id: T.String({
        title: "AggregateId",
        description: "The aggregate the event belongs to",
      }),
      id: T.Number(), // TODO: just make it ulid
      ref_id: RefId,
      type,
      name,
      data,
      created_at: DateTime,
      // This must have been a hack for starting with only one event type
      //  other events have an implicit source, like an activity is always from the system
      // TODO: narrow event source types?
      // TODO: move source to payload? — No available for filtering(?)
      source: T.Union([T.Literal("user"), T.Literal("system"), T.Literal("gpt")]),
      /** @deprecated @since ∞ */
      source_id: T.Optional(
        // identifies a source like a "gpt", "system", or "operateor"
        T.String({ title: "SourceId", description: "The actor/model/session? source" }),
      ),
      /** @deprecated @since ∞ */
      stream_id: T.Optional(
        T.String({ title: "StreamId", description: "The stream the event belongs to" }),
      ),
      /** @deprecated @since ∞ */
      coorelated_id: T.Optional(
        T.String({ title: "CoorelatedId", description: "The id of the coorelated event thread" }),
      ),
    },
    {
      title: pascalcase([type.const, "Event"]),
      ...options,
    },
  );

export const MessageAddedEvent = InputEventType({
  description: "A message from the user",
  type: T.Literal("message"),
  name: T.Literal("message:added"),
  data: T.Object({
    // FIXME: we will use the row id as its message id and propogate that through
    // id: T.String(),
    text: T.String(),
    /**
     * @deprecated should be handled by Activity event?
     */
    streamId: T.Optional(T.String()),
    /**
     * @deprecated should be handled by Response type?
     */
    promptId: T.Optional(T.Number()),
  }),
});
export const MessageEditedEvent = InputEventType({
  description: "The message edit event",
  type: T.Literal("message"),
  name: T.Literal("message:edited"),
  data: T.Object({
    messageId: RefId,
    eventId: T.Number(),
    text: T.String(),
  }),
});
export const MessageDeletedEvent = InputEventType({
  description: "The message deletion event",
  type: T.Literal("message"),
  name: T.Literal("message:deleted"),
  data: T.Object({
    messageId: RefId,
    eventId: T.Number(),
  }),
});
export const ActivityStartedEvent = InputEventType({
  description: "A record of an active process for subscription",
  type: T.Literal("activity"),
  name: T.Literal("activity:started"),
  data: T.Object({
    messageId: RefId,
    eventId: T.Number(),
    id: T.String(),
  }),
});
export const ActivityEndedEvent = InputEventType({
  description: "A record of an active process for subscription",
  type: T.Literal("activity"),
  name: T.Literal("activity:ended"),
  data: T.Object({
    messageId: RefId,
    eventId: T.Number(),
    id: T.String(),
  }),
});
export const PromptAddedEvent = InputEventType({
  description: "A prompt for the user from the system",
  type: T.Literal("prompt"),
  name: T.Literal("prompt:added"),
  data: T.Object({
    id: T.String(),
    query: T.String(),
    options: T.Array(T.String()),
  }),
});
export const ErrorAddedEvent = InputEventType({
  description: "A system error requiring intervention",
  type: T.Literal("error"),
  name: T.Literal("error:added"),
  data: T.Object({
    id: T.String(),
    text: T.String(),
  }),
});
export const ResponseAddedEvent = InputEventType({
  description: "A response from the system to a message",
  type: T.Literal("response"),
  name: T.Literal("response:added"),
  data: T.Object({
    id: T.String(),
    text: T.String(),
  }),
});
export const InputEventUnion = T.Union(
  [
    MessageAddedEvent,
    MessageEditedEvent,
    MessageDeletedEvent,
    ActivityStartedEvent,
    ActivityEndedEvent,
    PromptAddedEvent,
    ErrorAddedEvent,
    ResponseAddedEvent,
  ],
  { title: "InputEventUnion" },
);

export const inputEventMap = InputEventUnion.anyOf.reduce(
  (acc, v) => ({ ...acc, [v.properties.name.const]: v }),
  {} as {
    [K in Static<typeof InputEventUnion>["name"]]: K extends Static<typeof InputEventUnion>["name"]
      ? typeof InputEventUnion
      : never;
  },
);

export const DecodeInputEventList = <T extends Static<typeof InputEventUnion>[]>(
  values: unknown[],
): T => values.map(DecodeInputEvent) as T;

export const DecodeInputEvent = <T extends Static<typeof InputEventUnion>, U = T>(value: U): T => {
  const schema =
    value && typeof value === "object" && "name" in value
      ? inputEventMap[value.name as keyof typeof inputEventMap]
      : InputEventUnion;

  try {
    return Decode(schema, value);
  } catch (error) {
    reportErrors(schema, value);
    throw error;
  }
};

// TODO: Rename DraftUserEvent
export const DecodeDraftEvent = <
  T extends Omit<Static<typeof InputEventUnion>, "id" | "user_id" | "created_at">,
  U = Omit<T, "id" | "user_id" | "created_at">,
>(
  value: U,
  omitted: readonly UserOmittedFields[] = draftOmittedFields.user,
): T => {
  const draftSchema = T.Omit(
    value && typeof value === "object" && "name" in value
      ? inputEventMap[value.name as keyof typeof inputEventMap]
      : InputEventUnion,
    omitted,
  );

  try {
    return Decode(draftSchema, value);
  } catch (error) {
    console.warn("error", error);
    reportErrors(draftSchema, value);
    throw error;
  }
};

export const DraftAdminEvent = <
  T extends Omit<Static<typeof InputEventUnion>, "id" | "created_at">,
  U = Omit<T, "id" | "created_at">,
>(
  value: U,
  omitted: readonly AdminOmittedFields[] = draftOmittedFields.admin,
): T => {
  return DecodeDraftEvent(value, omitted);
};

export const DraftEvent = <
  N extends keyof typeof inputEventMap,
  T extends Omit<(typeof inputEventMap)[N], "id" | "created_at"> = Omit<
    (typeof inputEventMap)[N],
    "id" | "created_at"
  >,
>(
  value: T | unknown,
  omitted: readonly UserOmittedFields[] = draftOmittedFields.user,
): T => {
  const draftSchema = T.Omit(
    value && typeof value === "object" && "name" in value
      ? inputEventMap[value.name as keyof typeof inputEventMap]
      : InputEventUnion,
    omitted,
  );

  try {
    return Decode(draftSchema, value);
  } catch (error) {
    reportErrors(draftSchema, value);
    throw value;
  }
};

function reportErrors<Schema extends TSchema, Value extends Static<Schema>>(
  schema: Schema,
  value: Value | unknown,
) {
  const errors = TypeCompiler.Compile(schema).Errors(value);
  console.log("▅▅", schema.title);
  for (const error of errors) {
    const path = error.path.split("/").filter(Boolean).join(".");

    console.log(
      "— ",
      error.message,
      "for",
      "." + path,
      error.schema.title ? `(${error.schema.title})` : " ",
    );
    if (error.value !== "undefined") {
      console.log("—   ", "value:", error.value);
    }
  }
  console.log(value);

  console.log("▅▅");
}
