/** [ ] | &hairsp; */
export const hair = <>&#8202;</>;

/** [ ] */
export const em6 = <>&#8198;</>;

/** [ ] */
export const narrowNoBreak = <>&#8239;</>;

/** [ ] | &thinsp; */
export const thin = <>&#8201;</>;

/** [ ] | &emsp14; */
export const em4 = <>&#8197;</>;

/** [ ] | &nbsp; */
export const nonBreaking = <>&#160;</>;

/** [ ] | &puncsp; */
export const punctuation = <>&#8200;</>;

/** [ ] | &emsp13; */
export const em3 = <>&#8196;</>;

/** [ ] | &ensp; */
export const en = <>&#8194;</>;

/** [ ] | &numsp; */
export const figure = <>&#8199;</>;

/** [ ] | &emsp; */
export const em = <>&#8195;</>;

export const whitespace = {
  hair,
  em6,
  narrowNoBreak,
  thin,
  em4,
  nonBreaking,
  punctuation,
  em3,
  en,
  figure,
  em,
};
