import { useAtomValue } from "jotai";
import { Fragment } from "react";
import { css, cx } from "~/panda/css";
import { whitespace } from "~/react/components/whitespace";
import { streamAtomFamily } from "../atoms";
import { usePatchAtomFamily } from "../atoms/use-patch-atom-family";
import { messageFont } from "../fonts";
import { MessageEnvelope } from "../chat-reducer";

const sources = {
  user: "user prompt",
  gpt: "gpt response",
  system: "system message",
} as const;

export function Message({
  id,
  version,
  message,
  log: { length },
  log: { [length - 1]: event },
}: MessageEnvelope) {
  const fresh = Date.now() - new Date(event.created_at).getTime() <= 10000;
  const stream = useAtomValue(streamAtomFamily(id));
  const content = stream
    ? stream.parts.reduce((content, part) => content + part.content, "")
    : // @ts-expect-error: what should we descriminate?
      (message?.content?.text as string);

  return (
    <div className={cx("py-1", fresh && css({ animation: "appear-up" }))}>
      <div className="w-full flex gap-2 items-center text-md font-semibold pt-3 pb-1">
        <h6 className="" style={{ fontVariant: "small-caps" }}>
          {!message || message.source === "system"
            ? `${event.source} ${event.type}`
            : sources[message.source]}
        </h6>

        <div
          className="text-xs text-gray-400 font-normal"
          style={{ position: "relative", top: ".5px" }}
        >
          {event.id}
        </div>
      </div>
      <div className={cx("max-w-fit whitespace-break-spaces", messageFont.className)}>
        {content
          ?.split(/\n\s*\n/)
          .filter(Boolean)
          .map((v, i, { [i + 1]: next }) => (
            <Fragment key={`part:${i}`}>
              {v?.trim().replace(/\n\s+/g, "\n")}
              {next == null ? <Space /> : <div className="text-xs leading-0">{"\n"}</div>}
            </Fragment>
          ))}
        {event.name === "activity:started" && <Cursor key={`cursor:${event.id}`} />}
      </div>
    </div>
  );
}

function MessageContent({ content }: { content?: string }) {
  return (
    <>
      {content
        ?.split(/\n\s*\n/)
        .filter(Boolean)
        .map((v, i, { [i + 1]: next }) => (
          <Fragment key={`part:${i}`}>
            {v?.trim().replace(/\n\s+/g, "\n")}
            {next == null ? <Space /> : <div className="text-xs leading-0">{"\n"}</div>}
          </Fragment>
        ))}
    </>
  );
}

function ActiveMessageContent({ id }: Pick<MessageEnvelope, "id">) {
  const stream = useAtomValue(usePatchAtomFamily(streamAtomFamily, id));
  const content = stream?.parts.reduce((content, part) => content + part.content, "");

  return <MessageContent content={content} />;
}

const cursor = css({
  // verticalAlign: "text-bottom",
  // display: "inline-block",
  backgroundColor: "currentColor",
  animation: "pulse-and-disappear 1s cubic-bezier(0.4, 0, 0.6, 1) infinite",
  animationDelay: "-50ms",
  borderRadius: "xs",
  opacity: 0,
});

const Space = () => <span className={css({ ml: ".33em" })} />;
const Cursor = () => (
  <span className={cursor}>
    {whitespace.figure}
    {whitespace.punctuation}
  </span>
);
