import { atom } from "jotai";
import { useSession } from "~/lib/auth/hooks";
import { TRefId } from "../ids";
import { TInputEvent, TMessageAddedEvent } from "../event-types";
import { atomWithDevStorage } from "./atom-with-dev-storage";

export const sessionAtom = atom<ReturnType<typeof useSession> | undefined>(undefined);

export const navigationVisibleAtom = atomWithDevStorage("navigationVisible", false);

export const historyAtom = atom<Record<PropertyKey, readonly TInputEvent[]>>({});
export const currentChatIdAtom = atom<string | undefined>(undefined);

export const sourceAtom = atom<"user" | "system" | "gpt">("user");

export const draftAtom = atom<boolean>((get) => {
  const currentChatId = get(currentChatIdAtom);
  const history = get(historyAtom);
  return !history[currentChatId ?? ""]?.length;
});
// this will drive navigation
export const recentChatsAtom = atom((get) => {
  const history = get(historyAtom);
  return recentChatsForHistory(history);

  // TODO: move to "db.selectors"
  function recentChatsForHistory(history: Record<PropertyKey, readonly TInputEvent[]>) {
    return Object.entries(history ?? {})
      .filter(([, events]) => events?.length)
      .map(([chatId, events]) => {
        const first = events.find((event) => event.name === "message:added") as
          | TMessageAddedEvent
          | undefined;
        const last = events.findLast((event) => event.name === "message:added") as
          | TMessageAddedEvent
          | undefined;

        return {
          id: chatId,
          title: first?.data.text.slice(0, 128),
          created_at: first?.created_at ?? new Date().toISOString(),
          updated_at: last?.created_at ?? new Date().toISOString(),
        };
      });
  }
});


export const streamAtom = atom<
  | {
      messageId: TRefId;
      promptId: string | number;
      streamId: string;
      source: string;
      status: "open" | "closed";
      parts: { content: string; index: number }[];
      stop: () => void;
    }
  | undefined
>(undefined);
