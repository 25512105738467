"use client";

import { ReactNode } from "react";
import { navigationVisibleAtom } from "../atoms";
import { css, cx } from "~/panda/css";
import { useAtom } from "jotai";

export function PrimaryContentSlotClient({ children }: { children: ReactNode }) {
  const [inBackground, setBackground] = useAtom(navigationVisibleAtom);
  return (
    <div
      className={cx(
        css({ minHeight: "100dvh" }),
        "sm:max-w-screen-sm md:max-w-screen-md w-full overflow-x-hidden",
        "flex flex-col justify-end",
        "transform transition-transform",
        inBackground ? "translate-x-14" : "-translate-x-0",
        css({
          // it's no fun having tailwind and panda fighting …
          transitionDuration: "350ms !important",
          transitionTimingFunction: "ease",
          paddingTop: "calc(env(safe-area-inset-top))",
          paddingBottom: "calc(env(safe-area-inset-bottom))",
        }),
      )}
      onClick={() => {
        if (inBackground) {
          setBackground(false);
        }
      }}
    >
      {children}
    </div>
  );
}
