import { createActivity } from "./create-activity";

import { Static, Type as T } from "@sinclair/typebox";

// TODO: pass in validator
const GPTStreamEvent = T.Object({
  name: T.Literal("streaming"),
  payload: T.Object({ part: T.String(), index: T.Number() }),
});
type TGPTStreamEvent = Static<typeof GPTStreamEvent>;

export const GPTStreamActivity = createActivity<"gpt:stream", TGPTStreamEvent>(
  // TODO: even "as const" it's not being inferred as a literal
  //       we want to have all values infered, likely due to passing the event type
  //       if it can be extracted and required to provide something like the TObject for an acitivity
  "gpt:stream" as const,
);
