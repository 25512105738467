"use client";

import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import DocumentPlusIcon from "@heroicons/react/24/outline/DocumentPlusIcon";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { Fragment, useEffect, useRef } from "react";
import seedrandom from "seedrandom";

import { css, cx } from "~/panda/css";
import { browser } from "~/tools/browser";
import { currentChatIdAtom, draftAtom, navigationVisibleAtom, recentChatsAtom } from "../atoms";
import { ChatLink } from "../components/chat-link";
import { useScrollAnchor } from "../components/scrollview-anchor";
import { navigationFont } from "../fonts";

// import Acceleration from "@icon-park/react/es/icons/Acceleration";
// import { Acceleration } from "@icon-park/react";
import { Snail } from "lucide-react";

export function NavigationSidebarClient() {
  const [visible, setVisible] = useAtom(navigationVisibleAtom);
  const draft = useAtomValue(draftAtom);
  const recentChats = useAtomValue(recentChatsAtom);
  const currentChatId = useAtomValue(currentChatIdAtom);
  const scrollviewRef = useRef<HTMLDivElement>(null);
  const anchor = useScrollAnchor(scrollviewRef, [visible, currentChatId]);
  useEffect(() => {
    anchor.focus("instant", true);
    setTimeout(() => anchor.focus("instant", true), 100);
  }, [visible, currentChatId]);

  if (browser.window) {
    // @ts-ignore
    browser.window.anchor = anchor;
  }

  return (
    <div
      ref={scrollviewRef}
      // TODO: check in on this wack oversizing on the layout
      className={cx(
        "fixed z-20 -top-40 -bottom-40 pt-40 pb-40 left-0 inset-y-0",
        "flex flex-col",
        "bg-white/10 dark:bg-zinc-900/70 overflow-x-hidden w-[42rem] max-w-full shadow-2xl",
        visible ? "shadow-2xl" : "shadow-none",
        "transform transition-transform duration-300",
        visible ? "translate-x-0" : "-translate-x-full",
        css({ transitionTimingFunction: "navigation" }),
        css({ scrollbar: "hidden" }),
      )}
      style={{ WebkitOverflowScrolling: "touch" }}
    >
      {/* TODO: remove this or the inner one */}
      <div className="p-2" style={{ flex: "1 1 auto" }} />

      {/* we require a relative element for our backdrop element to size from */}
      <div className="relative flex flex-col">
        {/* we require a seperate backdrop element to allow for our blur mask */}
        <div
          className="absolute -z-10 -top-40 right-0 -bottom-40 left-0 backdrop-blur-md"
          style={{
            // @ts-ignore
            "--tw-backdrop-blur": "blur(7px)",
            mask: "linear-gradient(to left, #000D 0px, black 4px)",
          }}
        />

        {/* TODO: remove this or the outer one */}
        <div className="p-2" style={{ flex: "1 1 auto" }} />

        <ul
        // className="overflow-hidden max-w-42 sticky top-0 flex flex-col"
        >
          {recentChats.map((chat) => (
            <Fragment key={chat.id}>
              <StyledChatLink id={chat.id} selectedChatId={currentChatId}>
                {chat.title}
              </StyledChatLink>
              {chat.id === currentChatId && (
                <div className="relative">
                  <div ref={anchor.ref} className="absolute top-64" />
                </div>
              )}
            </Fragment>
          ))}
          {draft ? (
            <StyledChatLink id={currentChatId ?? "/"} selectedChatId={currentChatId}>
              Draft
            </StyledChatLink>
          ) : (
            <StyledChatLink id="/">New Chat</StyledChatLink>
          )}
        </ul>

        <div
          className={cx("py-5 h-[5.5rem] w-full sticky bottom-0 backdrop-blur")}
          style={{
            // @ts-ignore
            "--tw-backdrop-blur": "blur(1.5px)",
            // backgroundImage: `linear-gradient(to top, #2226 50%, #0000 100%)`,
            mask: "linear-gradient(transparent 0%,  black 5%)",
          }}
        >
          <div className="flex justify-between items-center w-full">
            <button className="px-2 pr-4" onClick={() => setVisible(!visible)}>
              <ChevronLeftIcon className="w-6 h-6" />
            </button>
            <Snail
              size="24"
              className={cx(
                "mx-4 text-emerald-300",
                visible &&
                  css({
                    animationDelay: "-.33s",
                    animation: "twirl-and-grow 1.33s 1 cubic-bezier(.5, .75, .75, 1.25)",
                  }),
              )}
              strokeWidth={1.75}
            />
            <ChatLink className={css({ display: "flex", placeContent: "center" })} id="/">
              <button className="px-2 pr-4" onClick={() => setVisible(!visible)}>
                <DocumentPlusIcon className="w-6 h-6" />
              </button>
            </ChatLink>
          </div>
          <div
            // TODO: CLASS VARIANCE FUCKING AUTHORITY …
            className={css({
              paddingBottom: "calc(.5rem + env(safe-area-inset-bottom))",
            })}
          />
        </div>
      </div>
    </div>
  );
}

function StyledChatLink({
  id,
  children,
  selectedChatId,
  ...props
}: {
  id: "/" | string;
  selectedChatId?: string;
  children: React.ReactNode;
}) {
  const selected = id && id === selectedChatId;
  const setNavigationVisible = useSetAtom(navigationVisibleAtom);

  return (
    <ChatLink
      id={id}
      onClick={(e) => setNavigationVisible(false)}
      className={cx("block max-w-10 px-[1px] pb-[1px]")}
    >
      <div
        className={cx(
          "px-2 py-1 rounded-sm h-10 flex items-center",
          selected
            ? " bg-white text-black shadow-sm dark:text-white dark:bg-blue-700 dark:active:bg-blue-700/90 md:dark:hover:bg-blue-700/80"
            : "active:bg-slate-50 dark:text-blue-50 dark:active:bg-blue-700/80 md:dark:hover:text-blue-100 md:dark:hover:bg-blue-100/5",
        )}
      >
        <div
          className={cx(
            "mr-3 flex-shrink-0",
            selected ? "h-5 w-2 rounded-sm" : "h-2 w-2 rounded-full",
          )}
          style={{
            ...getRandomTailwindColor(id ?? "draft"),
          }}
        ></div>
        <div
          className={cx(
            "text-sm  truncate",
            navigationFont.className,
            !selected && fadeout,
            // css({ fontSize: "13.5px !important" }),
          )}
        >
          {children}
        </div>
      </div>
    </ChatLink>
  );
}

const fadeout = css({
  // TODO: fix
  backgroundImage: {
    // base: "linear-gradient(to right, currentColor 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
    base: "linear-gradient(to right, var(--colors-gray-900) 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
    _osLight:
      "linear-gradient(to right, var(--colors-gray-900) 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
    _osDark:
      "linear-gradient(to right, currentColor 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
  },
  // background: "linear-gradient(to right, currentColor 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
  // "linear-gradient(to right, currentColor 0%, currentColor min(80%, min(60vw, 20rem)), #FFFA min(90%, min(70vw, 28rem)), #FFF4 min(105%, min(80vw, 36rem)), #FFF0 min(105%, min(100vw, 48rem)));",
  // _light: {
  //   background:
  //     "linear-gradient(to right, var(--colors-gray-900) 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
  // },
  // _osDark: {
  //   background:
  //     "linear-gradient(to right, currentColor 0%, currentColor min(60vw, 20rem), #FFF4 min(80vw, 36rem), #FFF0 min(100vw, 48rem));",
  // },

  backgroundClip: "text",
  WebkitAppearance: "text",
  WebkitTextFillColor: "transparent",
  // WebkitBoxDecorationBreak: "clone",e
});

function getRandomTailwindColor(id: string) {
  // const colors = ["green", "blue", "indigo", "purple", "pink", "red", "yellow"];
  const colors = [
    "sky",
    "cyan",
    "teal",
    "emerald",
    "green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "red",
    "fuchsia",
    "pink",
    "rose",
    "violet",
    "purple",
    "blue",
    "indigo",
    // "neutral",
    // "stone",
    // "zinc",
    // "gray",
    // "slate",
  ];
  const shades = ["100", "200", "300", "400", "500", "600", "700", "800", "900"].slice(0, 5);

  const randomColor = colors[Math.floor(seedrandom(id.slice(-10))() * colors.length)];
  const randomShade = shades[Math.floor(seedrandom(id)() * shades.length)];

  return {
    backgroundColor: `var(--colors-${randomColor}-${randomShade})`,
  };
}
