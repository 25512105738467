"use client";

import { F } from "@mobily/ts-belt";
import { useSetAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { useParams } from "next/navigation";
import React, { useEffect } from "react";
import { useSession } from "~/lib/auth/hooks";
import { chatAtomFor, currentChatIdAtom, historyAtom, sessionAtom } from "../atoms";
import { SetupAction } from "../chat-reducer";
import { TInputEvent } from "../event-types";

export function HydrateClient(props: {
  selectedChatId: string;
  history: Record<PropertyKey, readonly TInputEvent[]>;
  children: React.ReactNode;
}) {
  useHydrateSession();
  useHydrateAtoms([
    [historyAtom, props.history],
    [currentChatIdAtom, props.selectedChatId],
    [
      chatAtomFor(props.selectedChatId),
      SetupAction(props.selectedChatId, props.history[props.selectedChatId] ?? []),
    ],
  ]);

  // TODO: remove this is already happening … which means there's a full page load happening
  const [id] = useParams().path;
  const setCurrentChatId = useSetAtom(currentChatIdAtom);
  useEffect(() => {
    if (id !== props.selectedChatId) {
      setCurrentChatId(id);
    }
  }, [id]);

  return <>{props.children}</>;
}

function useHydrateSession() {
  const session = useSession();
  useHydrateAtoms([[sessionAtom, session]]);

  const setSession = useSetAtom(sessionAtom);
  useEffect(() => {
    setSession((prev) => {
      if (prev?.status === session.status && F.equals(prev.data, session.data)) return prev;
      else {
        console.log("updating session atom", session.status, session.data);

        return session;
      }
    });
  }, [session.status, session.data]);
}
