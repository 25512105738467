import { useEffect, useRef, useState } from "react";

export function useIsMounted() {
  const ref = useRef(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(() => (ref.current = true));
    return () => void (ref.current = false);
  }, []);

  return Object.assign(mounted, { ref });
}
