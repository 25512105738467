import { DependencyList, RefObject, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import useLatest from "use-latest";

import { useWindowResizeListener } from "~/react/hooks/use-listener";

export function useScrollAnchor(
  scrollviewRef: RefObject<HTMLElement>,
  dependencies?: DependencyList,
) {
  const waypoint = useLatest(useInView());
  const direction = useLatest(useDownDelayedScrollDirection(scrollviewRef.current));
  const shouldScroll = useLatest(direction.current !== "up" && waypoint.current.inView);

  // Scroll to the bottom of the window
  const scrolledRef = useRef<number>(0);
  function setScrollPosition(behavior?: ScrollBehavior, forced?: boolean) {
    behavior = typeof behavior === "string" ? behavior : "smooth";
    if (!scrollviewRef.current) {
      return;
    }
    const el = scrollviewRef.current;
    const scrollHeight = el.scrollHeight;
    const delta = scrollHeight - scrolledRef.current;
    if (forced || scrolledRef.current !== scrollHeight) {
      scrolledRef.current = scrollHeight;
      el.scrollTop = scrollHeight;
      behavior = delta <= 32 && distanceFromBottom(el) < 32 ? "instant" : behavior;
      el.scrollTo({
        top: scrollHeight,
        behavior: delta <= 32 ? "instant" : behavior,
      });
      return;
    }
  }

  // on load
  useLayoutEffect(() => {
    if (!waypoint.current.inView) {
      setScrollPosition("instant", true);
      // TODO: validate that this is needed
      const id = setTimeout(() => setScrollPosition("instant"), 100);
      return () => clearTimeout(id);
    }
  }, []);

  // on change to deps, like a new message
  useLayoutEffect(() => {
    if (shouldScroll.current) setScrollPosition();
  }, dependencies);

  if (typeof window === "object") {
    // on window resize
    useWindowResizeListener(() => {
      if (shouldScroll.current) setScrollPosition("instant");
    });
  }

  return {
    focus(behavior?: ScrollBehavior, force?: boolean) {
      // console.log("focus", behavior, force);
      setScrollPosition(behavior, force);
    },
    focusIfNeeded(behavior?: ScrollBehavior, force?: boolean) {
      // console.log("focusIfNeeded", behavior, force);
      shouldScroll.current && setScrollPosition(behavior, force);
    },

    get visible() {
      return waypoint.current.inView;
    },
    get ref() {
      return waypoint.current.ref;
    },
    get element() {
      return (
        <div
          key="anchor"
          ref={waypoint.current.ref}
          style={{ visibility: "hidden", display: "none" }}
        />
      );
    },
  };
}

function useDownDelayedScrollDirection(el?: null | HTMLElement) {
  const [scrollDirection, setScrollDirection] = useState<"up" | "down" | "idle">("idle");

  useEffect(() => {
    if (!el) return;

    let lastScrollTop = getScrollTop(el);
    let downId: NodeJS.Timeout;
    let idleId: NodeJS.Timeout;

    function onScroll() {
      const scrollTop = getScrollTop(el!);

      if (scrollTop < lastScrollTop) {
        setScrollDirection("up");
      } else if (scrollTop > lastScrollTop) {
        clearTimeout(downId);
        downId = setTimeout(() => setScrollDirection("down"), 100);
      }
      lastScrollTop = scrollTop;

      // set to "idle" 500ms after last interaction
      clearTimeout(idleId);
      idleId = setTimeout(() => setScrollDirection("idle"), 500);
    }

    el.addEventListener("scroll", onScroll);
    return () => {
      clearTimeout(downId);
      clearTimeout(idleId);
      el.removeEventListener("scroll", onScroll);
    };
  }, []);

  return scrollDirection;
}

export function getScrollTop(el: HTMLElement) {
  return el.scrollTop;
}

function distanceFromBottom(el: HTMLElement) {
  const scrollPosition = getScrollTop(el);
  const viewportHeight = window.innerHeight;
  const totalDocumentHeight = el.scrollHeight;

  return totalDocumentHeight - (scrollPosition + viewportHeight);
}
