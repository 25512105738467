// type ChatEvent = {
//   id: string;
//   user_id: string;
//   aggregate_id: string;
//   type: "message:added" | "message:edited" | "message:deleted";
//   message_id: string;
//   created_at: string;
//   content?: string;
// };
import { A } from "@mobily/ts-belt";
import {
  TActivityEndedEvent,
  TActivityStartedEvent,
  TEvent,
  TInputEvent,
  TMessageAddedEvent,
  TMessageDeletedEvent,
  TMessageEditedEvent,
} from "./event-types";
import { debug } from "./debug";
import { Identify, TRefId } from "./ids";

export type MessageState = IdleMessageState | PendingMessageState | ActivityState;
type BareMessageState = {
  id: TRefId;
  source: "system" | "user" | "gpt";
  state: "idle" | "pending" | "activity";
  mode: "idle" | "started" | "ended";
  created_at: string;
  updated_at: string;
  promptId?: string | number;
  streamId?: string;
  // TODO: add author details
  // author
};
type IdleMessageState = BareMessageState & {
  state: "idle";
  content: Pick<TMessageAddedEvent["data"], "text">;
};
type ActivityState = BareMessageState & {
  state: "activity";
  promptId: string | number;
  streamId: string;
};
type PendingMessageState = BareMessageState & {
  state: "pending";
};

export type MessageEnvelope<T extends MessageState = MessageState> = {
  id: TRefId;
  version: string | number;
  message: T | undefined;
  versions: readonly MessageState[];
  log: readonly TInputEvent[];
};

export type ChatState = {
  aggregate_id: string;
  log: readonly TInputEvent[];
  history: readonly MessageEnvelope[];
};

export const ChatStateDefault = ({ aggregate_id }: { aggregate_id: string }): ChatState => ({
  aggregate_id,
  log: [],
  history: [],
});

export const SetupAction = (aggregate_id: string, history: readonly TInputEvent[]) =>
  ResetAction(ChatStateDefault({ aggregate_id }), history);

export const ResetAction = (state: ChatState, history: readonly TInputEvent[]) =>
  ({ type: "reducer:reset", state, history }) as const;

export function rebuildChat(aggregate_id: string, history: readonly TInputEvent[]): ChatState {
  const state = ChatStateDefault({ aggregate_id });
  return reduceChat(state, ResetAction(state, history));
}

// TODO: implement dry edits, forking will be specific event type like message:forked
export function reduceChat(
  state: ChatState | undefined,
  event: TInputEvent | ReturnType<typeof ResetAction>,
): ChatState {
  // handle reset events before validations
  if (event.type === "reducer:reset")
    return Array.isArray(event.history)
      ? event.history.reduce(reduceChat, event.state)
      : event.state;

  return __reduceChat(state, event);
}

export function __reduceChat(state: ChatState | undefined, event: TInputEvent): ChatState {
  // TODO: remove condition if we find a better way of working with jotai
  if (!state) throw new Error("reduceChat: state has not been hydrated");
  // validate we initialized with an aggregate_id
  if (!state.aggregate_id) throw new Error("reduceChat: aggregate_id not set");

  // validate our event is for the same aggregate
  if (event.aggregate_id !== state.aggregate_id) return state;

  const log = upsertEvent(state.log, event);
  if (log === state.log) {
    return state;
  }

  // apply event to state
  switch (event.name) {
    case "message:added": {
      const history = updateByEvent(state.history, event, (envelope, event) => {
        if (envelope && event.source === "system") {
          event = { ...event, ref_id: Identify("ref") };
        } else if (envelope && envelope.message?.state !== "activity") {
          return ERROR(
            "Attempted to add message that already exists",
            { envelope, event },
            envelope,
          );
        }
        const messageId = event.ref_id;
        const message: IdleMessageState = {
          id: messageId,
          state: "idle",
          mode: "idle",
          source: event.source,
          content: {
            text: event.data.text,
          },
          created_at: event.created_at,
          updated_at: event.created_at,
          promptId: event.data.promptId,
          streamId: event.data.streamId,
        };

        return {
          // envelope id === version id === last event id?
          id: messageId,
          message,
          version: event.id,
          log: [...(envelope?.log ?? []), event],
          versions: [...(envelope?.versions ?? []), message],
        };
      });

      return { ...state, log, history };
    }
    case "message:edited": {
      // const envelopeId = event.data.streamId ?? event.id
      const history = update(state.history, event.data.messageId, (envelope) => {
        if (!envelope)
          return ERROR(
            "Attempted to edit message that does not exist",
            { envelope, event },
            envelope,
          );
        if (!envelope.message)
          return ERROR(
            "Attempted to edit message that does not exist",
            { envelope, event },
            envelope,
          );
        if (envelope.message.state !== "idle")
          return ERROR(
            "Attempted to edit message that is active or pending",
            { envelope, event },
            envelope,
          );

        const message: IdleMessageState = {
          ...envelope.message,
          content: {
            text: event.data.text,
          },
        };

        return {
          ...envelope,
          message,
          log: [...envelope.log, event],
          versions: [...envelope.versions, message],
        };
      });

      return { ...state, log, history };
    }
    case "message:deleted": {
      const history = update(state.history, event.data.messageId, (envelope) => {
        if (!envelope)
          return ERROR(
            "Attempted to delete message that does not exist",
            { envelope, event },
            envelope,
          );
        if (!envelope.message)
          return ERROR("Attempted to delete message twice", { envelope, event }, envelope);

        return {
          ...envelope,
          message: undefined,
          log: [...envelope.log, event],
        };
      });
      return { ...state, log, history };
    }
    // case "message:pending": — is conceptually this
    // case "activity:started":
    case "activity:started": {
      const messageId = event.data.messageId;
      const history = update(state.history, messageId, (envelope) => {
        if (envelope && envelope.message?.state === "activity")
          return ERROR(
            `Attempted to start activity that already exists`,
            { envelope, event },
            envelope,
          );

        const message: ActivityState = {
          updated_at: event.created_at,
          source: envelope?.message?.source ?? event.source,
          state: "activity",
          mode: "started",
          id: messageId,
          promptId: event.data.eventId,
          streamId: event.data.id,
          created_at: envelope?.message?.created_at ?? event.created_at,
        };
        return {
          id: messageId,
          version: event.id,
          message,
          versions: [message],
          log: [event],
        };
      });

      return { ...state, log, history };
    }
    case "activity:ended": {
      const log = upsertEvent(state.log, event);
      if (log === state.log) {
        return state;
      }
      // the streamId
      const messageId = event.data.messageId;
      const history = update(state.history, messageId, (envelope) => {
        if (!envelope)
          throw ERROR(
            "Attempted to end activity that does not exist",
            { envelope, event },
            envelope,
          );
        if (!envelope.message)
          throw ERROR(
            "Attempted to end activity on deleted message",
            { envelope, event },
            envelope,
          );
        if (
          !envelope.log.findLast(
            (e) => e.name === "activity:started" && e.data.id === event.data.id,
          )
        )
          return ERROR(
            `Attempted to end activity that has not started`,
            { envelope, event },
            envelope,
          );

        if (
          envelope.log.findLast((e) => e.name === "activity:ended" && e.data.id === event.data.id)
        )
          return ERROR(
            "Attempted to end activity that has already ended",
            { envelope, event },
            envelope,
          );

        // @ts-expect-error: there's got to be a better way
        const message: IdleMessageState = {
          ...envelope.message,
          state: "idle",
          mode: "ended",
        };

        return {
          id: envelope.id,
          version: event.id,
          message,
          log: [...envelope.log, event],
          versions: [...envelope.versions, message],
        };
      });

      if (state.history === history) {
        ERROR("no change to history", { state, event }, state);
      }

      return { ...state, log, history };
    }
    default:
      // console.info("reduceMessages: unhandled event type", event.type, event);
      return state;
  }
}

/**
 * Generic sort for event upsert
 */
export function upsertEvent<T extends TEvent>(events: readonly T[], event: T) {
  // assumes events are ordered by default, so should never look more than once
  const lastIndex = events.findLastIndex((e) => e.id <= event.id);
  if (lastIndex === -1) {
    return [event, ...events];
  }
  if (events[lastIndex]?.id === event.id) {
    return events;
  }
  return A.insertAt(events, lastIndex + 1, event);
}

export function update<T extends { id: string }>(
  list: readonly T[],
  docId: T["id"] | ((doc: T) => boolean),
  update: (state?: T) => T | null | undefined,
): readonly T[] {
  const isDoc = typeof docId === "function" ? docId : (doc: T) => doc.id === docId;
  // assumes events are ordered by default, so should never look more than once
  const index = list.findLastIndex(isDoc);

  const doc = update(list[index]);
  if (doc === list[index]) return list;
  if (doc == null) {
    // return if missing or remove
    return index === -1 ? list : A.removeAt(list, index);
  }
  // add new doc
  if (index === -1) return [...list, doc];
  // ignore update and return original list
  if (list[index] === doc) return list;

  // replace doc at index
  return isDoc(doc) ? A.replaceAt(list, index, doc) : A.insertAt(list, index + 1, doc);
}

export function updateByEvent<T extends { id: string }, E extends TInputEvent>(
  list: readonly T[],
  event: E,
  updateValue: (state: T | undefined, event: E) => T | null | undefined,
) {
  return update<T>(list, event.ref_id, (doc) => updateValue(doc, event));
}

export function insertEvent<T extends TEvent>(events: readonly T[], event: T) {
  // assumes events are ordered by default, so should never look more than once
  const lastIndex = events.findLastIndex((e) => e.id <= event.id);
  return events[lastIndex]?.id === event.id
    ? A.replaceAt(events, lastIndex, event)
    : A.insertAt(events, lastIndex + 1, event);
}

function ERROR<T>(message: string, meta: any, state: T) {
  console.error(message);
  for (const [key, value] of Object.entries(meta)) console.info(message, key, value);

  return state;
}
function WARN<T>(message: string, meta: any, state: T) {
  console.warn(message);
  for (const [key, value] of Object.entries(meta)) console.info(message, key, value);
  return state;
}
