"use client";

import MenuIcon from "@heroicons/react/24/solid/Bars3Icon";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { css, cx } from "~/panda/css";

import {
  chatAtomFor,
  currentChatIdAtom,
  navigationVisibleAtom,
  sourceAtom,
  streamAtomLastActiveFor,
} from "../atoms";
import { usePatchAtomFamily } from "../atoms/use-patch-atom-family";
import { Input, InputContainer } from "../components/input";
import { RecallLink } from "../components/recall-link";
import { HonestInputEvent } from "../db";
import { useNewMessage } from "../hooks/use-new-message";
import { useIsMounted } from "~/react/hooks/use-is-mounted";
import { ChevronRightSquare, Library, LibrarySquare, PanelLeftOpen } from "lucide-react";

export function HonestInputClient({ aggregate_id }: { aggregate_id: string }) {
  const isNavigating = aggregate_id !== useAtomValue(currentChatIdAtom);
  const isNavigationVisible = useAtomValue(navigationVisibleAtom);
  const streaming = useAtomValue(usePatchAtomFamily(streamAtomLastActiveFor, aggregate_id));
  const addPendingEvent = useAddPendingEvent(aggregate_id);
  const { draft, setDraft, onSubmit } = useNewMessage({ aggregate_id, onMessage: addPendingEvent });
  const source = useAtomValue(sourceAtom);
  return (
    <>
      <form
        className={cx(
          "w-full min-w-fit pt-2 pb-6 gap-2 flex items-center",
          "relative",
          isNavigationVisible ? "z-0" : "z-[100]",
        )}
        onSubmit={onSubmit}
      >
        <NavigationButton className="pl-2" />

        <Input
          inactive={isNavigating || isNavigationVisible}
          mode={streaming?.status === "open" ? "streaming" : "prompt"}
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onStop={() => streaming?.stop()}
        >
          <SourceSelect className="w-fit" />
          {/* <RecallLink aggregate_id={aggregate_id} /> */}
        </Input>
        <div />
      </form>
      {/* <div className="w-full min-w-fit pt-3 px-2 pb-3 gap-2 flex items-center">
      </div> */}
    </>
  );
}

function useAddPendingEvent(aggregate_id: string) {
  const dispatch = useSetAtom(chatAtomFor(aggregate_id));
  // TODO: mark as pending
  return (event: HonestInputEvent) => {
    return dispatch(event);
  };
}

function NavigationButton({ className }: { className?: string }) {
  const [value, setValue] = useAtom(navigationVisibleAtom);
  return (
    <button className={className} type="button" onClick={() => setValue(!value)}>
      {/* <PanelLeftOpen className="w-6 h-6" strokeWidth={1.5} /> */}
      <Library className="w-6 h-6" strokeWidth={1.5} />
      {/* <MenuIcon className="w-6 h-6" /> */}
    </button>
  );
}

function SourceSelect({ className }: { className?: string }) {
  const [source, setSource] = useAtom(sourceAtom);
  return (
    <select
      onChange={(e) => setSource(e.target.value as any)}
      value={source}
      className={cx(
        className,
        "bg-transparent border-none border-0 border-transparent focus:shadow-none outline-none focus:outline-none flex-1",
        css({
          width: "fit-content",
          maxWidth: "3rem",
          boxShadow: "none !important",
          paddingLeft: "1.75rem !important",
          paddingRight: "0 !important",
          backgroundPosition: "left .25rem center !important",
        }),
      )}
    >
      <option value="user">🧑‍💻</option>
      <option value="system">💻</option>
      <option value="gpt">🤖</option>
    </select>
  );
}
