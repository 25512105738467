import { atom } from "jotai";

import { TRefId } from "../ids";
import { chatAtomFor, currentChatState } from "./chat-state";
import { patchAtomFamily } from "./use-patch-atom-family";

type StreamStateMap = Record<TRefId, StreamState>;
export type StreamState = {
  messageId: TRefId;
  promptId: string | number;
  streamId: string;
  source: string;
  status: "open" | "closed";
  parts: readonly { content: string; index: number }[];
  stop: () => void;
};

export const streamMapAtom = atom<StreamStateMap>({});
export const streamAtomFamily = patchAtomFamily((_messageId: TRefId) => {
  const streamAtom = atom(
    (get) => {
      const streams = get(streamMapAtom);
      return streams[_messageId];
    },
    (get, set, update: StreamState) => {
      set(streamMapAtom, (streams) => {
        return { ...streams, [_messageId]: update };
      });
    },
  );

  return streamAtom;
});

export const messageAtomLastActiveFor = patchAtomFamily((chatId: string) => {
  return atom((get) => {
    const chat = get(chatAtomFor(chatId));
    return chat.history.findLast((envelope) => envelope.message?.state === "activity");
  });
});

export const streamAtomLastActiveFor = patchAtomFamily((chatId: string) => {
  return atom((get) => {
    const envelope = get(messageAtomLastActiveFor(chatId));
    if (envelope) return get(streamMapAtom)[envelope.id];
  });
});

export const messageLastActiveForCurrentChatAtom = atom((get) => {
  const currentChat = get(currentChatState);
  return currentChat.history.findLast((envelope) => envelope.message?.state === "activity");
});

export const streamLastActiveForCurrentChatAtom = atom((get) => {
  const envelope = get(messageLastActiveForCurrentChatAtom);
  if (envelope) return get(streamMapAtom)[envelope.id];
});
