import { SupabaseClient } from "@supabase/supabase-js";
import { SupabaseAdminClient } from "~/lib/supabase/admin";
import { createBroadcast } from "./create-broadcast";
import { createReceiver } from "./create-receiver";
import { TActivityStreamEvent, TActivityStreamMeta } from "./active-event-types";

export function createActivity<
  Topic extends string,
  Event extends TActivityStreamEvent,
  Meta extends TActivityStreamMeta = TActivityStreamMeta,
>(topic: Topic) {
  return {
    willBroadcast: (supabase: SupabaseAdminClient, meta: Meta) =>
      createBroadcast<Topic, Event, Meta>(supabase, topic, meta),
    willReceive: (supabase: SupabaseClient, meta: { aggregateId: string }) =>
      createReceiver<Topic, Event, Meta>(supabase, topic, meta),
  };
}
