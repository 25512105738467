import {
  ObjectOptions,
  Static,
  Type as T,
  TIntersect,
  TLiteral,
  TObject,
  TUnion,
} from "@sinclair/typebox";
import { ulid } from "ulidx";

export type TEventId = Static<typeof EventId>;
export type TRefId = Static<typeof RefId>;
export type TMessageId = Static<typeof MessageId>;
export type TVersionId = Static<typeof VersionId>;
export type TPromptId = Static<typeof PromptId>;
export type TReplyId = Static<typeof ReplyId>;
export type TRequestId = Static<typeof RequestId>;
export type TResponseId = Static<typeof ResponseId>;
export type TActivityId = Static<typeof ActivityId>;

export const EventId = T.Number({
  title: "EventId",
  description: "The system generated event id",
});
// client generated
export const RefId = T.TemplateLiteral("ref:${string}", {
  title: "RefId",
  description:
    "The user/client/command generated reference id used to track the state of a command in a user interface.",
});
export const RecallId = T.TemplateLiteral("recall:${string}", {
  title: "RefId",
  description:
    "The user/client/command generated reference id used to track thew message outputs of a command",
});
// system generated, message envelope
export const MessageId = T.TemplateLiteral("message:${string}", {
  title: "MessageId",
  description: "The id of the message envelope, shared by all versions of the message.",
});
// the id of the specific version of the message
export const VersionId = T.TemplateLiteral("version:${string}", {
  title: "VersionId",
  description: "The id of the specific version of the message.",
});
// the id of the specific prompt
export const PromptId = T.TemplateLiteral("prompt:${string}", {
  title: "PromptId",
  description: "The id of the specific prompt.",
});
// the id of the prompt's reply
export const ReplyId = T.TemplateLiteral("reply:${string}", {
  title: "ReplyId",
  description: "The id of the prompt's reply.",
});
// the id of the specific request for reply for a specific prompt
export const RequestId = T.TemplateLiteral("request:${string}", {
  title: "RequestId",
  description: "The id of the specific request for reply for a specific prompt.",
});
// the id of a reply's specific response to a given prompt
export const ResponseId = T.TemplateLiteral("response:${string}", {
  title: "ResponseId",
  description: "The id of a reply's specific response to a given prompt.",
});
// the id of a specific activity, like the stream process for receiving a result
export const ActivityId = T.TemplateLiteral("activity:${string}", {
  title: "ActivityId",
  description: "The id of a specific activity, like the stream process for receiving a result.",
});

export function Identify<
  T extends
    | "ref"
    | "message"
    | "version"
    | "prompt"
    | "reply"
    | "request"
    | "response"
    | "activity",
>(type: T): `${T}:${string}` {
  return `${type}:${ulid()}`;
}

export function shortIdFor(id: string): string {
  const [value, prefix] = id.split(":").reverse();
  return [prefix, value.slice(-6)].filter(Boolean).join(":");
}
