import ArrowRightIcon from "@heroicons/react/20/solid/ArrowRightIcon";
import StopCircleIcon from "@heroicons/react/20/solid/StopCircleIcon";
import { S } from "@mobily/ts-belt";
import {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";

import { css, cx } from "~/panda/css";
import { useAnchorScroll } from "./anchor";
import { useIsMounted } from "~/react/hooks/use-is-mounted";
import { SendHorizontal, Send, ArrowUpCircle, MessageCirclePlus, ChevronRight } from "lucide-react";

type InputProps =
  // InputHTMLAttributes<HTMLInputElement> |
  TextAreaProps;
type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  TextareaAutosizeProps & {
    type?: "textarea";
    submitOn?: "none" | "enter" | "shift+enter";
    onSubmit?: (e: any) => any;
  };

// FIXME: not happy with the ownership of control here
export type StreamingProps = {
  mode: "streaming" | "prompt";
  onStop: (e: any) => any;
};
export function Input<T extends InputProps & { inactive: boolean }>({
  inactive,
  mode,
  onStop,
  children,
  ...props
}: T & StreamingProps) {
  const [submitted, setSubmitted] = useState(false);
  const [focused, setFocused] = useState(false);
  const handlers = {
    onFocus: (e: any) => (setFocused(true), props.onFocus?.(e)),
    onBlur: (e: any) => (setFocused(false), props.onBlur?.(e)),
    onSubmit: (e: any) => (setSubmitted(true), props.onSubmit?.(e)),
  } satisfies InputProps;

  const anchor = useAnchorScroll([]);
  // useLayoutEffect(() => {
  //   if (focused || (!focused && submitted)) {
  //     anchor.focus("instant", true);
  //   }
  // }, [focused]);
  useLayoutEffect(() => {
    // reset submit state when value resets
    if (submitted && !props.value) {
      setSubmitted(false);
    }
    // for a submitted state all dep changes trigger this action
    if (submitted) {
      anchor.focus();
      const id = setTimeout(anchor.focus, 100);
      return () => clearTimeout(id);
    }
  }, [submitted, !props.value]);

  const isMounted = useIsMounted();
  return (
    <>
      <InputContainer disabled={props.disabled} inactive={inactive}>
        {children}

        {isTextArea(props) ? (
          <TextArea {...props} {...handlers} onHeightChange={() => anchor.focus("instant")} />
        ) : (
          (console.error("What the heck? You can't do that! 👀"),
          (<TextInput {...(props as any)} {...handlers} />))
        )}

        {mode === "streaming" ? (
          <button
            className={cx("pr-2", css({ minHeight: "11" }))}
            onClick={(e) => {
              e.preventDefault();
              onStop(e);
            }}
          >
            <StopCircleIcon className="text-orange-400 w-7 h-7" />
          </button>
        ) : (
          <button
            type="submit"
            // FIXME: padding via panda is not working
            className={cx("pr-1", css({ minHeight: "11" }))}
          >
            <ChevronRight
              strokeWidth={1.75}
              className={cx(
                // "w-6 h-6",
                "transition-colors duration-150",
                props.value ? "text-blue-500" : "currentColor",
              )}
            />
            {/* <ArrowRightIcon
              className={cx(
                "w-7 h-7",
                "transition-colors duration-150",
                props.value ? "text-blue-500" : "currentColor",
              )}
            /> */}
          </button>
        )}
      </InputContainer>

      {anchor.element}
    </>
  );
}

function TextArea({ submitOn = "enter", onSubmit, ...props }: TextAreaProps) {
  const isHandheld =
    typeof window !== "undefined" &&
    // switch to matchMedia for all feature detection?
    // && window.matchMedia("(min-width: 768px)").matches
    ("ontouchstart" in window || navigator.maxTouchPoints) &&
    (window.innerWidth <= 800 || window.innerHeight <= 600);

  // set submitOn to "shift+enter" on mobile
  submitOn ??= isHandheld ? "shift+enter" : "enter";

  const submitRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <input
        ref={submitRef}
        type="submit"
        className={css({ visibility: "hidden", height: 0, width: 0 })}
      />

      <TextareaAutosize
        {...props}
        onKeyDown={(e) => {
          // opt out of default behavior
          if ((props.onKeyDown?.(e) as any) === false) return;
          // handle submit on enter
          if (
            e.key === "Enter" &&
            (e.shiftKey ? submitOn === "shift+enter" : submitOn === "enter") &&
            S.trim(e.currentTarget.value)
          ) {
            e.preventDefault();
            submitRef.current?.click();
            isHandheld && e.currentTarget.blur();
            onSubmit?.(e);
            return false;
          }
        }}
        className={cx(
          "appearance-none overflow-hidden",
          "px-1 w-fit h-11 flex-1 outline-none border-none rounded-lg bg-transparent text-md",
          "focus:ring-0 focus:border-none focus:outline-none focus:border-transparent !focus:outline-transparent",
          props.className,
          "resize-none",
        )}
      />
    </>
  );
}

function TextInput(props: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <>
      <input
        {...props}
        type="text"
        className={cx(
          "appearance-none overflow-hidden",
          "px-1 w-fit h-11 flex-1 outline-none border-none rounded-lg bg-transparent text-md",
          "focus:ring-0 focus:border-none focus:outline-none focus:border-transparent !focus:outline-transparent",
        )}
      />
    </>
  );
}

function isTextArea(props: InputProps): props is TextAreaProps {
  return !props.type ? true : props.type === "textarea";
}

export function InputContainer({
  children,
  className = "flex-1 w-full",
  disabled,
  inactive,
}: {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  inactive?: boolean;
}) {
  const isMounted = useIsMounted();
  return (
    <div
      className={cx(
        className,
        css({ minHeight: "11" }),
        "overflow-hidden",
        "flex items-center rounded-lg",
        "ring-1",
        "ring-zinc-200 dark:ring-zinc-700/80",
        "bg-slate-100/20 dark:bg-zinc-800/70",
        "transition-shadow",
        inactive || disabled || !isMounted.ref.current
          ? "duration-200"
          : "duration-500 shadow-lg shadow-slate-800/10 dark:shadow-slate-900/25",
      )}
    >
      {children}
    </div>
  );
}
