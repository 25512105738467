import { useSetAtom } from "jotai";
import Link from "next/link";
import { currentChatIdAtom } from "../atoms";
import { ulid } from "ulidx";
import { useEffect, useState } from "react";

export function ChatLink({
  id,
  onClick,
  ...props
}: Omit<Parameters<typeof Link>[0], "href"> & {
  id: "/" | string;
}) {
  const setSelected = useSetAtom(currentChatIdAtom);

  // TODO: we really want a single point of id generation
  //       and no id generation on the frontend
  //       since they're uncontrolled string inputs
  // id = id === "/" ? ulid() : id;
  const [_id, set_id] = useState(id);
  useEffect(() => {
    if (_id === "/") set_id(ulid());
  }, []);

  return (
    <Link
      // prefetch
      shallow
      scroll={false}
      href={["/honest/input", _id].filter(Boolean).join("/")}
      onClick={(e) => {
        setSelected(id);
        onClick?.(e);
      }}
      {...props}
    />
  );
}
