import { atom } from "jotai";
import { atomWithDefault } from "jotai/utils";
import { rebuildChat, reduceChat } from "../chat-reducer";
import { currentChatIdAtom, historyAtom } from "./chat";
import { patchAtomFamily } from "./use-patch-atom-family";
import { shortIdFor } from "../ids";

export const currentChatState = atom((get) => {
  return get(chatAtomFor(get(currentChatIdAtom)!));
});

export const chatAtomFor = patchAtomFamily((aggregate_id: string) => {
  return createChatStateAtom(aggregate_id);
});

export function createChatStateAtom(aggregate_id: string) {
  const eventsAtom = atom((get) => get(historyAtom)[aggregate_id] ?? []);
  const snapshotAtom = atomWithDefault((get) => {
    console.log("atom rebuilding snapshot for", shortIdFor(aggregate_id));
    return rebuildChat(aggregate_id, get(eventsAtom));
  });

  return atom(
    (get) => get(snapshotAtom),
    (get, set, action: Parameters<typeof reduceChat>[1]) => {
      let prev = get(snapshotAtom);
      if (action.type === "reducer:reset") {
        // NOTE: when touching history this will trigger a "setState within render"
        setTimeout(() => {
          set(historyAtom, (history) => ({ ...history, [aggregate_id]: action.history }));
          set(snapshotAtom, rebuildChat(aggregate_id, action.history));
        }, 0);
      } else {
        const next = reduceChat(prev, action);
        if (next.log.length > prev.log.length) {
          set(historyAtom, (history) => ({ ...history, [aggregate_id]: next.log }));
          set(snapshotAtom, reduceChat(prev, action));
        }
      }
    },
  );
}
