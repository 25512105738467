import { createBrowserClient as browse } from "@supabase/ssr";
import mem from "mem";

import { Database } from "~/supabase/types";
import { NEXT_PUBLIC_SUPABASE_ANON_KEY, NEXT_PUBLIC_SUPABASE_URL } from "./env";

export type SupabaseBrowserClient<D extends Database = Database> = ReturnType<typeof browse<D>>;
export const createBrowserClient = mem(function createBrowserClient<D extends Database = Database>(
  token?: string,
) {
  if (token == null) {
    console.warn("Supabase browser session will be anonymous");
    token = NEXT_PUBLIC_SUPABASE_ANON_KEY;
  }

  const client = browse<D>(NEXT_PUBLIC_SUPABASE_URL, NEXT_PUBLIC_SUPABASE_ANON_KEY, {
    cookies: {},
    global: { headers: { Authorization: `Bearer ${token}` } },
  });

  // TODO: drop these and see if it still works … might cause a bug
  // client.functions.setAuth(token);
  client.realtime.setAuth(token);
  client.realtime.accessToken = token;

  return client satisfies SupabaseBrowserClient<D>;
});
