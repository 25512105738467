import { SupabaseClient } from "@supabase/supabase-js";
import { TActivityStreamEvent, TActivityStreamMeta, TBroadcastEvent } from "./active-event-types";

export function createReceiver<
  Topic extends string,
  Event extends TActivityStreamEvent,
  Meta extends TActivityStreamMeta = TActivityStreamMeta,
>(supabase: SupabaseClient, topic: Topic, meta: { aggregateId: string }) {
  const channel = supabase.channel(`honest-input/${meta.aggregateId}`, {
    config: { broadcast: { ack: true } },
  });

  const stream = {
    __channel: channel,
    __on: channel.on.bind(channel),
    __send: channel.send.bind(channel),
    on<Name extends TBroadcastEvent<Event, Meta>["name"]>(
      name: Name,
      callback: (event: Extract<TBroadcastEvent<Event, Meta>, { name: Name }>) => void,
    ) {
      channel.on("broadcast", { event: `${topic}:${name}` }, ({ name, payload, meta }) => {
        // TODO: we love validation
        callback({ name, payload, meta } as Extract<TBroadcastEvent<Event, Meta>, { name: Name }>);
      });
      return stream;
    },
    subscribe(...args: Parameters<typeof channel.subscribe>) {
      channel.subscribe(...args);
      return stream;
    },
    unsubscribe() {
      channel.unsubscribe();
      supabase.removeChannel(channel);
      return stream;
    },
  };
  return stream;
}
