import { atom, useAtomValue } from "jotai";
import { atomFamily } from "jotai/utils";
import { SupabaseBrowserClient, createBrowserClient } from "~/lib/supabase/browser";
import { sessionAtom } from "./chat";
import { useEffect, useRef } from "react";
import { F } from "@mobily/ts-belt";

export const supabaseAtom = atomFamily((mode: "anonymous" | "authenticated") =>
  atom((get) => {
    // we're binding both anonymous and authenticated to the session
    const session = get(sessionAtom);
    // NOTE: we were unable to use atomEffect to unmount subscriptions

    let client: SupabaseBrowserClient;
    if (mode === "authenticated") {
      if (!session) {
        throw new Error("Session atom must be hydrated for authenticated mode");
      }
      if (session.status === "authenticated" && !session.data?.supabase?.token) {
        throw new Error("Session atom must provide supabase token");
      }
      if (session.status === "loading") {
        return undefined;
      }

      client = createBrowserClient(session.data?.supabase?.token);
    }
    // TODO: we no longer need to tie this to session
    else client = createBrowserClient();

    return Object.assign(client, {
      __user: session?.data?.user,
      __mode: mode,
    });
  }),
);

export function useSupabaseAtom(mode: "anonymous" | "authenticated") {
  const client = useAtomValue(supabaseAtom(mode));

  let ref = useRef(client);
  useEffect(() => {
    ref.current = client;
    return () => {
      if (!F.equals(ref.current?.__user, client?.__user)) {
        console.log("unmounting supabase atom");
        client?.removeAllChannels();
      }
    };
  }, [client?.__user]);

  return client;
}
