"use client";

import { ReactNode } from "react";
import { navigationVisibleAtom } from "../atoms";
import { css, cx } from "~/panda/css";
import { useAtom } from "jotai";

export function BottomSheetSlotClient({ children }: { children: ReactNode }) {
  const [inBackground, setBackground] = useAtom(navigationVisibleAtom);
  return (
    <div
      className={cx(
        "w-full fixed bottom-0 flex items-center justify-center",
        inBackground ? "z-0" : "z-[100]",
        // TODO: CVA
        css({ userSelect: "none" }),
      )}
      onClick={() => {
        if (inBackground) {
          setBackground(false);
        }
      }}
    >
      <div
        className="absolute -z-20 top-0 right-0 bottom-0 left-0 bg-slate-100 dark:bg-zinc-800"
        style={{
          mask: "linear-gradient(to bottom, #0000 0px, #0009 18px, #000A 32px, #000F 100%)",
        }}
      />
      <div
        className="absolute -z-10 top-0 right-0 bottom-0 left-0 backdrop-blur-md "
        style={{
          // @ts-ignore
          "--tw-backdrop-blur": "blur(1.5px)",
          mask: "linear-gradient(to bottom, #0000 0px, #000F 32px)",
        }}
      />
      {/* TODO: turn this into a CVA for primary elements, these are the screen boundaries */}
      <div
        className={cx(
          "sm:max-w-screen-sm md:max-w-screen-md w-full",

          css({
            paddingBottom: "calc(.75rem + env(safe-area-inset-bottom))",
          }),
        )}
      >
        {children}
      </div>
    </div>
  );
}
