import { Atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { useEffect, useRef } from "react";
import { shortIdFor } from "../ids";

if (!isSWCJotaiActive()) {
  console.info("PatchAtomFamily is disabled without @swc-jotai/react-refresh");
}

export interface PatchAtomFamily<Param, AtomType> {
  (param: Param): AtomType;
  remove(param: Param): void;
  setShouldRemove(shouldRemove: ShouldRemove<Param> | null): void;
  [PatchFlag]: boolean;
}
type ShouldRemove<Param> = (createdAt: number, param: Param) => boolean;

const PatchFlag = Symbol("patched");

export function patchAtomFamily<Param, AtomType extends Atom<unknown>>(
  initializeAtom: (param: Param) => AtomType,
  areEqual?: (a: Param, b: Param) => boolean,
): PatchAtomFamily<Param, AtomType> {
  const family = atomFamily(initializeAtom, areEqual);
  return Object.assign(family, { [PatchFlag]: true });
}

const activeAtoms = new WeakMap<Atom<unknown>, number>();
// TODO: remove everything here once swc-jotai is patched
export function usePatchAtomFamily<
  T extends PatchAtomFamily<any, any>,
  P extends T extends PatchAtomFamily<infer P, any> ? P : unknown,
  A extends T extends PatchAtomFamily<any, infer A> ? A : unknown,
>(atomFamily: T, param: P): A {
  const label = typeof param === "string" ? shortIdFor(param) : param;
  const atom = atomFamily(param);
  const timeoutRef = useRef<NodeJS.Timeout>();

  // add "management" — remove on unmount
  useEffect(() => {
    // clear timeout on reload
    if (timeoutRef.current && activeAtoms.get(atom) === 1)
      console.info("usePatchAtomFamily skipped removal for", label);
    if (timeoutRef.current) timeoutRef.current = void clearTimeout(timeoutRef.current);

    // exit early if not active
    if (!atomFamily[PatchFlag] || !isSWCJotaiActive()) return;

    const count = (activeAtoms.get(atom) ?? 0) + 1;
    activeAtoms.set(atom, count);
    if (count === 1) {
      console.info("usePatchAtomFamily will remove", label, "on unmount");
    }

    return () => {
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = undefined;

        const count = (activeAtoms.get(atom) ?? 0) - 1;
        activeAtoms.set(atom, count);
        if (count === 0) {
          console.info("usePatchAtomFamily removing", label, "on unmount");
          atomFamily.remove(param);
          // TODO: this will lead to a memory leak … but it's a weak map, so maybe not …
          // activeAtoms.delete(atom);
        }
      });
    };
  }, [param]);

  return atom;
}

function isSWCJotaiActive() {
  return true;
  return (
    "jotaiAtomCache" in
    (typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : {})
  );
}
