import React, { useState } from "react";

import { sourceAtom, useSupabaseAtom } from "../atoms";
import { HonestInputEvent, insertUserMessage } from "../db";
import { Identify } from "../ids";
import { useStore } from "jotai";

export type useNewMessageProps = {
  aggregate_id: string;
  onMessage?: (value: HonestInputEvent) => any;
};

export function useNewMessage({ aggregate_id, onMessage }: useNewMessageProps) {
  const supabase = useSupabaseAtom("authenticated");
  const [draft, setDraft] = useState("");
  const store = useStore();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!supabase) {
      throw new Error("Supabase unavailable, cannot submit");
    }
    // TODO: indicate transition state
    setDraft("");

    // create event record of message
    const message_id = Identify("ref");
    const source = store.get(sourceAtom);
    const inserted = await insertUserMessage(supabase, aggregate_id, message_id, draft, source);
    console.log("inserted", inserted);
    if (!inserted.data || inserted?.error) {
      setDraft(draft);
      // TODO: handle with error component catch?
      throw inserted.error;
    }
    // update parent with record
    onMessage?.(inserted.data as HonestInputEvent);
    // post query to message handlers
    const response = await fetch("/honest/input/api/inngest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(inserted.data),
    });
  };

  return { draft, setDraft, onSubmit };
}
