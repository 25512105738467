export function ensure<T>(
  value?: T | undefined | null,
  message = "Required value is undefined or null",
): NonNullable<T> {
  if (value == null) throw new Error(message);

  return value;
}
ensure.useServer = <T>(value: T, message?: string): NonNullable<T> =>
  typeof window === "undefined" ? ensure(value, message) : stub.server;
ensure.useBrowser = <T>(value: T, message?: string): NonNullable<T> =>
  typeof window !== "undefined" ? ensure(value, message) : stub.browser;

export function env(key: keyof NodeJS.ProcessEnv, defaultValue?: string): string {
  return ensure(process.env[key] ?? defaultValue, `Missing env var: ${key}`);
}
env.useServer = (key: keyof NodeJS.ProcessEnv, defaultValue?: string): string =>
  env(key, typeof window === "undefined" ? defaultValue : stub.server);
env.useBrowser = <T>(key: keyof NodeJS.ProcessEnv, defaultValue?: string): string =>
  env(key, typeof window !== "undefined" ? defaultValue : stub.browser);

const stub: Record<"server" | "browser", any> = {
  server: Object.freeze({ toJSON: () => "__SERVER_ONLY__", toString: () => "__SERVER_ONLY__" }),
  browser: Object.freeze({ toJSON: () => "__BROWSER_ONLY__", toString: () => "__BROWSER_ONLY__" }),
};
