import { env } from "~/tools/ensure";

declare namespace NodeJS {
  export interface ProcessEnv {
    SUPABASE_URL?: string;
    SUPABASE_ANON_KEY?: string;
    SUPABASE_SERVICE_ROLE_KEY?: string;
    SUPABASE_JWT_SECRET?: string;

    NEXT_PUBLIC_SUPABASE_URL?: string;
    NEXT_PUBLIC_SUPABASE_ANON_KEY?: string;
  }
}

export const SUPABASE_URL = env.useServer("SUPABASE_URL");
export const SUPABASE_ANON_KEY = env.useServer("SUPABASE_ANON_KEY");
export const SUPABASE_SERVICE_ROLE_KEY = env.useServer("SUPABASE_SERVICE_ROLE_KEY");
export const SUPABASE_JWT_SECRET = env.useServer("SUPABASE_JWT_SECRET");

export const NEXT_PUBLIC_SUPABASE_URL = env(
  "NEXT_PUBLIC_SUPABASE_URL",
  process.env.NEXT_PUBLIC_SUPABASE_URL,
);
export const NEXT_PUBLIC_SUPABASE_ANON_KEY = env(
  "NEXT_PUBLIC_SUPABASE_ANON_KEY",
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
);
